<template>
				
                <div class="card mt-4">
					<div class="card-header">
						<div class=" text-center">
				<div><h3>REVENUE</h3></div>
				<div class="row">
					<div class="col-md-3">

              <multiselect
                  :options="vendor_ids"
                  placeholder="Select Vendor"
                  v-model="vendor_id_value"
                  track-by="name"
                          label="name"
                >
                </multiselect>
					</div>
					<div class="col-md-1 text-center">
<b-button variant="primary" @click="apply_filter()">Apply</b-button>
					</div>
					<div class="col-md-4 text-center">
						<date-range-picker
    v-model="dateRange"
    @update="dateChange"
	:locale-data="{ format: 'dd-mm-yyyy' }"
  ></date-range-picker>
					</div>
					
					<div class="col-md-4 text-right">
  <button  type="button" class="btn btn-venuecolor"><i class="mdi mdi-export"></i>Export</button>

			</div>
			</div>
		</div>
					</div>
					<div class="card-body">
						<div class="table-responsive">
							<table class="table table-bordered table-md ">
								<thead >
									<tr>
										<th class="text-left">Month</th>
										<th class="text-left">No of Booking</th>
										<th class="text-left">Revenue</th>
										<th class="text-left">Gst</th>
										<th class="text-left">Total</th>
									
										<th class="text-left">Action </th>
									</tr>
								</thead>
								<tbody>
									<tr v-if="revenue.length < 0 ">
										<td colspan="6" class="text-center">No Data Found</td>
									</tr>
									<tr v-else class="" v-for="(revenu ,r) in revenue" :key="r">
										<td class="text-left">{{ revenu.month }}</td>
										<td class="text-left">{{ revenu.booking_count }}</td>
										<td class="text-right"><span v-html="currency_check(numberWithCommas(revenu.final_total))"></span></td>
										<td class="text-right"><span v-html="currency_check(numberWithCommas(revenu.gst_amount))"></span></td>
										<td class="text-right"><span v-html="currency_check(numberWithCommas(revenu.g_amt))"></span></td>
										<td  class="text-left"> 
											<router-link :to="'/Report/revenue_list/' + revenu.selectlink + '/'+vendor_id_value.value">
												<i class="fas fa-eye fa-lg text-dark cursor-pointer"></i>
											</router-link>
										</td>
										
										
									</tr>
									
								</tbody>
							</table>
						</div>
					</div>
				</div>
</template>

<script>
import axios from 'axios'
import Multiselect from "vue-multiselect";
	import helpers from '../../../mixins/helpers'; 
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'



	export default {
		mixins: [helpers],
		data:()=>({
			revenue:[],
			
			curncy:"",
			vendor_id_value:"",
			vendor_ids: [],
			
		dateRange: {
          startDate: new Date(),
          endDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
        },
}),
components: {DateRangePicker,Multiselect},
methods:
{
    revenue_report() {
      axios.post("/admin/revenue_report",{
		month:this.dateRange,
		vendor_id:this.vendor_id_value.value
	}).then((resp) => {
        if (resp.data.status_code == 200) {
          if (resp.data.status) {
            this.revenue=resp.data.revenue;
           
            
            
          }
        }
      });
    },
	get_venor_id() {
        /* axios.post("/api/get_vendor_ids").then((resp) => {
          if (resp.data.status_code == 200) {
            if (resp.data.status) {
              this.vendor_ids = resp.data.vendor_ids;
            }
          }
        }); */
  
        axios.post("/admin/get_all_vendors_list").then((resp) => {
          if (resp.data.status_code == 200) {
            if (resp.data.status) {
              for (var i = 0; i < resp.data.vendors.length; i++) {
                this.vendor_ids.push({
                  value: resp.data.vendors[i].user_id,
                  name:
                    resp.data.vendors[i].first_name +
                    " " +
                    resp.data.vendors[i].last_name,
                });
              }
            }
          }
        });
  
      },
	
	currency_check(amt) {
    return '<i class="mdi '+this.curncy+'"></i>'+amt;
			},
			getProfile() {
				axios.get("/api/get_profile").then((resp) => {
					if (resp.data.status_code == 200) {
						this.curncy= resp.data.users.currencys;
					}
				});
			},
			dateChange()
			{
				this.revenue_report();
			},
			apply_filter()
			{
				this.revenue_report();
			}
		},
		mounted()
		{
			this.getProfile();
			this.revenue_report();
			this.get_venor_id();
		}
    }
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>

</style>